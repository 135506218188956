import React from 'react';
import Man1 from 'images/men/Marc.svg';
import Man2 from 'images/men/Fabien.svg';
import Man3 from 'images/men/Richard.svg';
import { gilbertOnly } from 'components/common/data/doctors';

import lessonsData from './programData.json';

export const scientistData = {
  doctor: gilbertOnly,
  title: 'Le directeur scientifique des thérapies digitales',
  subtitle: '',
  body: "Le Dr Gilbert Bou Jaoudé est un docteur en médecine spécialisé en sexologie depuis plus de 25 ans. Cet ancien président de la Société Francophone de Médecine Sexuelle fait partie de l'aventure charles.co et mia.co et est aussi très présent dans les médias. Son objectif ? Faire de la santé sexuelle, une santé reconnue, sans tabous pour offrir de véritables solutions aux patients et patientes qui rencontrent des difficultés sexuelles. C'est aussi pour cela qu'il a initié et fait partie de nombreuses recherches et études sur la sexualité. Son approche est médicale, complète, douce et bienveillante.",
};

export const metaData = {
  title: 'Améliorer son érection avec le programme MASTER | Charles.co',
  description: 'Améliorez vos érections grâce au programme MASTER développé par des sexologues. 9 coaching vidéos, 12 exercices physiques et mentaux. Assistance illimitée.',
};

export const topSection = {
  h1: 'Programme MASTER',
  programSlug: 'master-program',
  ctaLabel: 'Tester gratuitement la première étape',
  borderIcon: 'border-sex-icon.svg',
  subtitle: (
    <div className="pathology-subtitle">
      Maîtrise Appliquée du Stress et des Troubles Érectiles
    </div>
  ),
  video: {
    titleDisplay: false,
    filename: 'videos/thumbnails/thumbnail-master-program.jpg',
    alt: 'Trailer Programme Master',
    title: 'Trailer Programme Master',
    video:
      'https://cdn.jwplayer.com/players/R9RzLQdw-KdT3I1iC.html',
  },
  reinsurance: [
    {
      text: '93% des patients interrogés constatent une amélioration durable',
      icon: 'icon-med-confiance-experts-square.svg',
    },
    {
      text: "8 semaines de thérapie complète issue de 25 ans d'expérience en sexologie et médecine sexuelle",
      icon: 'icon-med-videoconference-square.svg',
    },
    {
      text: 'À votre rythme et sans engagement : vous pouvez arrêter et reprendre le programme quand vous le souhaitez',
      icon: 'icon-large-rendez-vous-confirme-square.svg',
    },
    {
      text: 'Accompagnement par notre équipe tout au long du programme',
      icon: 'icon-med-specialistes-fr-square.svg',
    },
  ],
};

export const about = {
  h2: 'A propos du programme MASTER',
  accordion: [
    {
      title: 'Que vais-je trouver dans le programme MASTER ?',
      text:
        "Le programme MASTER (Maîtrise Appliquée du Stress et des Troubles de l'Erection) est un programme complet de 8 semaines, pendant lequel vous allez apprendre 12 techniques physiques et mentales pour comprendre et gérer vos érections. Le programme MASTER se compose de 8 vidéos de coaching en ligne, rythmant 8 étapes d'une semaine de travail spécifique et personnalisé.",
    },
    {
      title: 'À qui s’adresse le programme MASTER ?',
      text: 'Le programme MASTER s’adresse à tous les hommes qui souhaitent comprendre le fonctionnement érectile et qui voudraient mieux gérer leurs érections. Que vous rencontriez des problèmes érectiles occasionnels ou récurrents, ou que vous souhaitiez juste approfondir votre maîtrise de la sexualité, vous pouvez commencer le programme. Si vous êtes déjà sous traitement, cela peut être un complément, voire une alternative !',
    },
    {
      title: 'Y a-t-il un suivi personnalisé pendant la durée du programme ?',
      text: "Pendant tout le programme, nos coachs sont disponibles 5 jours sur 7 pour vous donner des explications, des conseils et vous remotiver. Vous pouvez parfois avoir ce sentiments de démotivation, c'est normal et ce n'est pas grave. Si vous avez de la peine à avancer, prenez le réflexe de décrocher votre téléphone et d'appeler l'assistance MASTER. Vous ne serez jamais seul !",
    },
  ],
  definitionLabel: 'Docteur Glbert Bou Jaoudé',
  definition: (
    <div>
      "Le sujet est tellement tabou, que peu de personnes en parlent et de nombreux hommes se retrouvent au pied du mur ou n’imagineraient même pas qu’il existe des solutions pour s’en sortir. Pourtant, il en existe bien."
    </div>
  ),
};

export const whyItWorks = {
  title: 'Pourquoi est-ce efficace ?',
  text: "Le programme MASTER a été conçu par une équipe de médecins sexologues afin de couvrir plusieurs aspects vous permettant d'améliorer la qualité de vos érections en une cinquantaine de jours.",
  list: [
    {
      fullName: "Activer les zones de l'érection",
      description: "Vous allez apprendre les techniques permettant d'obtenir, avec la pratique et de l'entraînement, une connaissance et une maîtrise des muscles qui ont un rôle à jouer dans vos érections. Petit à petit, ces exercices physiques de plus en plus précis et avancés seront mêmes applicables lors des rapports.",
      pictures: {
        regular: 'new-images/yoga-man.jpg',
      },
    },
    {
      fullName: 'Prendre en compte votre mental',
      description: "La dimension psychologique est centrale en ce qui concerne le réflexe érectile. Le Dr Bou Jaoudé vous transmettra des exercices et des techniques mentales qui vous permettront de laisser de la place à votre instinct, tout en vous apprenant à orienter vos pensées qui mèneront à l'érection.",
      pictures: {
        regular: 'new-images/yoga-old-man.jpg',
      },
    },
    {
      fullName: 'À votre rythme et à la carte',
      description: "Ce programme est tout à fait flexible. Vous pouvez choisir entre suivre le rythme \"standard et conseillé\" ou suivre votre propre rythme en fonction de vos contraintes et de vos envies. Quoi qu'il arrive : notre équipe est là pour répondre à toutes vos questions, et vous assister si besoin.",
      pictures: {
        regular: 'new-images/gilbert-why-it-works.jpeg',
      },
    },
  ],
};

export const testimonies = {
  title: 'Nos patients témoignent',
  subtitle: 'Et ce sont eux qui en parlent le mieux',
  content: [
    {
      text: "Cela fait plusieurs années que je cherchais une solution naturelle aux troubles érectiles que je peux parfois rencontrer. C'est chose faite ! Le docteur est clair dans les vidéos et je me suis beaucoup aidé des feuilles d'exercices, petit à petit, je vois les résultats, j'ai hâte d'arriver à la fin ! Je pense que je vais continuer les exercices même après le programme.",
      info: 'Brice, 37 ans',
    },
    {
      text: "C'est sérieux, simple et surtout efficace. Ça m'a beaucoup aidé à comprendre comment les érections fonctionnaient vraiment, les techniques mentales sont parfois un peu difficiles à faire mais avec de l'entraînement on y arrive. Je suis satisfait du service proposé par le programme du Docteur Bou Jaoudé, j'ai vraiment l'impression d'avancer et de me sortir de la mauvaise spirale dans laquelle j'étais.",
      info: 'Jacky, 42 ans',
    },
    {
      text: "Bravo à l'équipe Charles pour ce programme, et pour le suivi des coachs qui m'ont aidé pendant ces 90 jours intenses.",
      info: 'Jules, 24 ans',
    },
  ],
};

export const sections = {
  title: ' ',
  videoList: [
    {
      filename: 'videos/thumbnails/gilbert1.png',
      alt: '',
      title: "Comment ça marche l'érection ?",
      video:
        'https://www.youtube.com/embed/RC35pfov2vA?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'RC35pfov2vA',
        title: "Comment ça marche l'érection ?",
        description: null,
        duration: '4:38',
        uploadDate: new Date(2019, 3, 16).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert3-1.png',
      alt: '',
      title: 'Comment reconnaître une dysfonction érectile ?',
      video:
        'https://www.youtube.com/embed/fWg7nNPmIRU?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'fWg7nNPmIRU',
        title: 'Comment reconnaître une dysfonction érectile ?',
        description: null,
        duration: '3:24',
        uploadDate: new Date(2019, 3, 11).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert6-1.png',
      alt: '',
      title:
        "Comment fonctionnent les médicaments contre les troubles de l'érection ?",
      video:
        'https://www.youtube.com/embed/i8cGg1J2w_U?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'i8cGg1J2w_U',
        title:
          "Comment fonctionnent les médicaments contre les troubles de l'érection ?",
        description: null,
        duration: '6:27',
        uploadDate: new Date(2019, 5, 7).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert8-1.png',
      alt: '',
      title: "Influence de certains médicaments sur l'érection",
      video:
        'https://www.youtube.com/embed/ysJ7Y6_5hMU?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'ysJ7Y6_5hMU',
        title: "Influence de certains médicaments sur l'érection",
        description: null,
        duration: '4:08',
        uploadDate: new Date(2019, 5, 24).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert7-1.png',
      alt: '',
      title: "Influence de l'état psychologique sur l'érection",
      video:
        'https://www.youtube.com/embed/ZggHQ0GmZbo?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'ZggHQ0GmZbo',
        title: "Influence de l'état psychologique sur l'érection",
        description: null,
        duration: '4:16',
        uploadDate: new Date(2019, 6, 21).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert10-1.png',
      alt: '',
      title: 'Quand consulter un médecin sexologue ?',
      video:
        'https://www.youtube.com/embed/5O2b_qxrEho?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: '5O2b_qxrEho',
        title: 'Quand consulter un médecin sexologue ?',
        description: null,
        duration: '2:25',
        uploadDate: new Date(2019, 5, 3).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert12-1.png',
      alt: '',
      title:
        "Y a-t-il des traitements contre les troubles de l'érection plus efficaces que d'autres ?",
      video:
        'https://www.youtube.com/embed/mn0M8gpHV1M?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'mn0M8gpHV1M',
        title:
          "Y a-t-il des traitements contre les troubles de l'érection plus efficaces que d'autres ?",
        description: null,
        duration: '4:01',
        uploadDate: new Date(2019, 5, 17).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert13.png',
      alt: '',
      title: 'Pourquoi a-t-on des érections le matin ?',
      video:
        'https://www.youtube.com/embed/hbv21fdGkhM?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'hbv21fdGkhM',
        title: 'Pourquoi a-t-on des érections le matin ?',
        description: null,
        duration: '5:30',
        uploadDate: new Date(2019, 5, 17).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert14-1.png',
      alt: '',
      title: "L'évolution des troubles de l'érection de 18 à 78 ans et plus",
      video:
        'https://www.youtube.com/embed/gakTq3seYuU?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'gakTq3seYuU',
        title: "L'évolution des troubles de l'érection de 18 à 78 ans et plus",
        description: null,
        duration: '7:11',
        uploadDate: new Date(2019, 5, 7).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert16.png',
      alt: '',
      title: "Les médicaments de l'érection sont-ils dangereux pour la santé ?",
      video:
        'https://www.youtube.com/embed/d3aWFE8kVQw?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'd3aWFE8kVQw',
        title:
          "Les médicaments de l'érection sont-ils dangereux pour la santé ?",
        description: null,
        duration: '6:36',
        uploadDate: new Date(2019, 5, 14).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert17.png',
      alt: '',
      title: "Quelles sont les causes d'une baisse de libido ?",
      video:
        'https://www.youtube.com/embed/Q1yd6CywKbQ?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'Q1yd6CywKbQ',
        title: "Quelles sont les causes d'une baisse de libido ?",
        description: null,
        duration: '4:51',
        uploadDate: new Date(2019, 5, 3).toISOString(),
      },
    },
    {
      filename: 'videos/thumbnails/gilbert18-2.png',
      alt: '',
      title: "10 choses à savoir sur les problèmes de l'érection",
      video:
        'https://www.youtube.com/embed/kCZOukKH9RU?feature=oembed&autoplay=1&rel=0',
      youtube: {
        videoId: 'kCZOukKH9RU',
        title: "10 choses à savoir sur les problèmes de l'érection",
        description: null,
        duration: '2:57',
        uploadDate: new Date(2019, 4, 4).toISOString(),
      },
    },
  ],
  faq: [
    {
      title: 'Que vais-je trouver dans le programme MASTER ?',
      text: (
        <div>
          Le programme MASTER (Maîtrise Appliquée du Stress et des Troubles de l'Erection) est un programme complet de 8 semaines, pendant lequel vous allez apprendre 12 techniques physiques et mentales pour comprendre et gérer vos érections. Le programme MASTER se compose de 8 vidéos de coaching en ligne, rythmant 8 étapes d'une semaine de travail spécifique et personnalisé. Tout au long du programme MASTER, vous bénéficierez d’un accompagnement quotidien à la demande de nos coachs par téléphone et par message. Ce programme unique et novateur a été conçu par le Docteur Gilbert Bou Jaoudé, médecin sexologue français, après avoir mené des tests depuis 25 ans sur les patients de son cabinet, et la plateforme charles.co, plateforme leader en Europe de la sexologie en ligne.
        </div>
      ),
    },
    {
      title: 'A qui s’adresse le programme MASTER ?',
      text: (
        <div>
          Le programme MASTER s’adresse à tous les hommes qui souhaitent comprendre le fonctionnement érectile et qui voudraient mieux gérer leurs érections. Que vous rencontriez des problèmes érectiles occasionnels ou récurrents, ou que vous souhaitiez juste approfondir votre maîtrise de la sexualité, vous pouvez commencer le programme. Si vous êtes déjà sous traitement, cela peut être un complément, voire une alternative !
        </div>
      ),
    },
    {
      title: 'Pourquoi le programme MASTER dure 8 semaines ?',
      text: (
        <div>
          Les 8 étapes du programme MASTER se déroulent chacune sur 1 semaine car c’est la durée optimale selon notre expérience pour pouvoir améliorer significativement et durablement vos réflexes mentaux et physiques liés à l'érection. Ces 8 semaines sont nécessaires pour assimiler et maîtriser les 12 techniques mentales et physiques que vous allez apprendre et qui vont vous permettre d’obtenir un contrôle de votre éjaculation. Si vous voulez prendre plus de temps et faire à votre rythme, c'est possible aussi, tant que vous restez régulier !
        </div>
      ),
    },
    {
      title: 'Combien de temps dois-je y consacrer ?',
      text: (
        <div>
          Pour garantir une efficacité maximale du programme MASTER, il est préconisé de réserver en moyenne 15 minutes pour une séance d’exercices et de faire au moins 7 séances d’exercices dans chaque étape de 7 jours. Cette discipline dans la fréquence des séances vous permettra d’obtenir des résultats significatifs et durables sur votre éjaculation. Certaines étapes du programme ne contiennent pas d'exercices et d'autres, en contiennent plusieurs.
        </div>
      ),
    },
    {
      title: 'Quels résultats attendre du programme MASTER ?',
      text: (
        <div>
          Au bout des 8 semaines, si vous avez suivi le rythme standard du programme, vous devriez être en mesure de maîtriser les techniques mentales et physiques qui vous permettront de mieux gérer vos érections et d'obtenir des érections plus fermes. Le plus important est surtout que vous preniez du plaisir et que le stress ne fasse plus partie de votre sexualité.
        </div>
      ),
    },
    {
      title: 'Que se passe-t-il si je n’y arrive pas ou si je me décourage ?',
      text: (
        <div>
          Vous pouvez parfois avoir ce sentiment, c'est normal et ce n'est pas grave. Si vous avez de la peine à avancer, prenez le réflexe de décrocher votre téléphone et d'appeler l'assistance MASTER. Pendant tout le programme, nos coachs sont disponibles 5 jours sur 7 pour vous donner des explications, des conseils et vous remotiver ! Vous ne serez jamais seul.
        </div>
      ),
    },
    {
      title: 'En quoi consiste l’assistance par téléphone et par message ?',
      text: (
        <div>
          En tant que membre du programme MASTER, vous aurez accès pendant les 8 semaines du programme à une assistance par téléphone et par message. Vous pourrez facilement être mis en relation avec des coachs spécialement formés par le Docteur Bou Jaoudé. Ils seront là pour vous accompagner dans la réussite de la thérapie et sont disponibles du lundi au vendredi de 9h30 à 18h30.
        </div>
      ),
    },
    {
      title: 'Comment savoir quels exercices faire à chaque étape ?',
      text: (
        <div>
          Au début de chaque étape, vous pourrez visionner à volonté la vidéo explicative des exercices de l’étape. Vous recevrez également par mail, au même moment, un programme détaillé d’exercices de l’étape. Ce récapitulatif vous permettra d'avoir un support et de vous organiser pour la semaine à venir. C'est simple et clairement expliqué. Si vous avez encore des questions, l’assistance est à votre disposition pour y répondre.
        </div>
      ),
    },
    {
      title: 'Comment passer d’une étape à la suivante ?',
      text: (
        <div>
          Pour valider une étape du programme MASTER, vous devez faire les exercices spécifiques de l’étape pendant une semaine. Lorsque vos 7 jours d'entraînement seront passés, vous recevrez la vidéo et les exercices suivants ! Si vous pensez que vous n'avez pas assez bien assimilé l'étape précédente, elle sera toujours disponible, n'hésitez pas à y revenir si besoin.
        </div>
      ),
    },
    {
      title: 'Pourquoi avoir choisi un médecin sexologue pour mener la thérapie ?',
      text: (
        <div>
          Afin de garantir le sérieux et l’efficacité de la méthode, Charles.co, plateforme leader de la sexologie en ligne en Europe, s’est appuyé sur le Docteur Gilbert Bou Jaoudé, médecin sexologue depuis 25 ans, ancien président la Société Française de Médecine Sexuelle, pour créer ce programme unique en son genre.
        </div>
      ),
    },
    {
      title: 'Comment puis-je régler le programme MASTER ?',
      text: (
        <div>
          Le règlement du programme MASTER peut s’effectuer en 1 ou 3 fois par carte bancaire. La plateforme Charles.co utilise le prestataire de paiement Sécurisé MANGOPAY. Le paiement apparaîtra de façon discrète sur votre relevé bancaire : MGP*CHARLESAS.
        </div>
      ),
    },
  ],
};

export default {
  sections,
  topSection,
  testimonies,
  about,
  whyItWorks,
  scientistData,
  metaData,
  lessonsData,
};
